import TextArea from "../../TextArea";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import { useState } from "react";

export default function TerminateModal({
  terminateModalIsOpen,
  setTerminateModalIsOpen,
  handleTerminateTripLocation,
  terminatingTripLocation,
}) {
  const tripLocationToTerminate = { ...terminateModalIsOpen };
  const [terminateExplanation, setTerminateExplanation] = useState("");

  return (
    <>
      <h3 className="mt-0">Originate</h3>

      <p>
        Are you sure you want to terminate the trip at{" "}
        <span className="font-bold">{tripLocationToTerminate?.location?.name}</span>?
      </p>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Explain termination <span className="text-error">*</span>
          </span>
        </label>
        <TextArea
          placeholder={"Explanation"}
          value={terminateExplanation}
          handleOnChange={(event) => setTerminateExplanation(event.target.value)}
        />
      </div>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-error"
          onClick={() => setTerminateModalIsOpen(false)}
        >
          Cancel
        </button>
        <button
          disabled={terminateExplanation === "" || terminatingTripLocation}
          className="btn btn-primary"
          onClick={() =>
            handleTerminateTripLocation(
              tripLocationToTerminate?.id,
              terminateExplanation
            )
          }
        >
          Terminate
          {terminatingTripLocation && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}
