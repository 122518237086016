import Checkbox from "./Checkbox";
import { useState, useEffect } from "react";

export default function YesNoCheckbox({ handleOnChange, name, string }) {
  const [yesBox, setYesBox] = useState(false);
  const [noBox, setNoBox] = useState(false);

  useEffect(() => {
    let newValue;

    if (yesBox && noBox) {
      newValue = null;
    } else if (yesBox && !noBox) {
      newValue = "TRUE";
    } else if (!yesBox && noBox) {
      newValue = "FALSE";
    } else if (!yesBox && !noBox) {
      newValue = undefined;
    }

    handleOnChange(newValue);
  }, [yesBox, noBox]);

  return (
    <div className="flex gap-4 items-center">
      <div className="w-32">
        <label className="font-bold">{name}</label>
      </div>

      <div className="flex gap-4">
        <div className="form-control">
          <label className="label gap-2 px-0 cursor-pointer">
            <span className="label-text">Yes</span>
            <Checkbox
              toggle={false}
              handleOnChange={(event) => {
                setYesBox(!yesBox);
              }}
            />
          </label>
        </div>

        <div className="form-control">
          <label className="label gap-2 px-0 cursor-pointer">
            <span className="label-text">No</span>
            <Checkbox
              toggle={false}
              handleOnChange={(event) => {
                setNoBox(!noBox);
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
}
