import { Link } from "react-router-dom";
import useFetchFunctions from "../../services/trips";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlarmClock,
  faArrowLeft,
  faInfoCircle,
  faPerson,
  faRoute,
  faTrain,
  faCheck,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAlarmClock as faAlarmClockSolid,
  faInfoCircle as faInfoCircleSolid,
  faPerson as faPersonSolid,
  faRoute as faRouteSolid,
  faTrain as faTrainSolid,
} from "@fortawesome/pro-solid-svg-icons";
import TripLocationsTable from "./trip-locations/TripLocationsTable";
import TripParameters from "./TripParameters";
import VehicleFormation from "./VehicleFormation";
import Occurrences from "./trip-occurrences/Occurrences";
import Toast from "../Toast";
import { useState, useRef, useContext, useEffect } from "react";
import { TripContext, NavigationContext } from "../../utils/Contexts";

export default function TripDetail() {
  const { fetchTrip, fetchOperatorTripLocationFields } = useFetchFunctions();
  const { operator_short_name, service_date, trip_name } = useParams();
  const [occurrenceDrawer, setOccurrenceDrawer] = useState(false);
  const [tripParametersDrawerIsOpen, setTripParametersDrawerIsOpen] =
    useState(false);
  const [showVehicleFormationDrawer, setShowVehicleFormationDrawer] =
    useState(false);
  const [currentTab, setCurrentTab] = useState("Info");
  const targetDivRef = useRef(null);

  const { currentTripContext, setCurrentTripContext } = useContext(TripContext);
  const { currentNavigationContext, setCurrentNavigationContext } =
    useContext(NavigationContext);

  const handleClickToScroll = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["trip", operator_short_name, service_date, trip_name],
    queryFn: () => fetchTrip(operator_short_name, service_date, trip_name),
    gcTime: 0,
  });

  const { data: operatorTripLocationFields } = useQuery({
    queryKey: ["operator_trip_location_fields", operator_short_name],
    queryFn: () => fetchOperatorTripLocationFields(operator_short_name),
    staleTime: 1000 * 10,
  });

  useEffect(() => {
    if (isLoading) {
      setCurrentTripContext({ isLoading: isLoading });
    }
    if (data && !isLoading) {
      setCurrentTripContext(data);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentNavigationContext("trip-detail");
  }, []);

  return (
    <div>
      <div className="page-header bg-info px-4 pt-3">
        <div className="prose text-white">
          <h1 className="flex gap-3 m-0 text-2xl lg:text-3xl text-white">
            <Link
              to="/trip-search"
              className="btn btn-sm lg:btn-md btn-square btn-ghost text-white"
            >
              <FontAwesomeIcon icon={faArrowLeft} size="xl" />
            </Link>
            <div>Trip {trip_name}</div>
          </h1>

          <Toast />

          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div className="flex gap-3 items-center ml-12">
              <span className="md:text-2xl font-normal text-white/50">
                {service_date?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1")}
              </span>
              <div className="badge badge-md badge-outline truncate">
                {data?.operator_service_day?.operator?.name}
              </div>
            </div>

            <div className="breadcrumbs ml-12 lg:ml-0 text-white hidden md:block not-prose">
              <ul>
                <li>
                  <Link to="/trip-search" className="link">
                    Trip Search
                  </Link>
                </li>
                <li className="text-white/50">
                  <div className="max-w-48 truncate">
                    Trip {trip_name} on{" "}
                    {service_date?.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 xl:col-span-8">
            <div
              role="tablist"
              className="tabs tabs-boxed bg-[#E5F6F9] p-2 mt-4 overflow-x-auto sm:hidden"
            >
              <button
                role="tab"
                onClick={() => setCurrentTab("Info")}
                className={`tab flex-col h-auto py-2 px-3 leading-6 ${currentTab === "Info" ? "tab-active" : ""}`}
              >
                {currentTab === "Info" ? (
                  <FontAwesomeIcon icon={faInfoCircleSolid} size="xl" />
                ) : (
                  <FontAwesomeIcon icon={faInfoCircle} size="xl" />
                )}
                Info
              </button>
              <button
                role="tab"
                onClick={() => setCurrentTab("Stops")}
                className={`tab flex-col h-auto py-2 px-3 leading-6 ${currentTab === "Stops" ? "tab-active" : ""}`}
              >
                {currentTab === "Stops" ? (
                  <FontAwesomeIcon icon={faRouteSolid} size="xl" />
                ) : (
                  <FontAwesomeIcon icon={faRoute} size="xl" />
                )}
                Stops
              </button>
              <button
                role="tab"
                onClick={() => setCurrentTab("Crew")}
                className={`tab flex-col h-auto py-2 px-3 leading-6 ${currentTab === "Crew" ? "tab-active" : ""}`}
              >
                {currentTab === "Crew" ? (
                  <FontAwesomeIcon icon={faPersonSolid} size="xl" />
                ) : (
                  <FontAwesomeIcon icon={faPerson} size="xl" />
                )}
                Crew
              </button>
              <button
                role="tab"
                onClick={() => setCurrentTab("Vehicles")}
                className={`tab flex-col h-auto py-2 px-3 leading-6 ${currentTab === "Vehicles" ? "tab-active" : ""}`}
              >
                {currentTab === "Vehicles" ? (
                  <FontAwesomeIcon icon={faTrainSolid} size="xl" />
                ) : (
                  <FontAwesomeIcon icon={faTrain} size="xl" />
                )}
                Vehicles
              </button>
              <button
                role="tab"
                onClick={() => setCurrentTab("Occurrences")}
                className={`tab flex-col h-auto py-2 px-3 leading-6 ${currentTab === "Occurrences" ? "tab-active" : ""}`}
              >
                {currentTab === "Occurrences" ? (
                  <FontAwesomeIcon icon={faAlarmClockSolid} size="xl" />
                ) : (
                  <FontAwesomeIcon icon={faAlarmClock} size="xl" />
                )}
                Occur.
              </button>
            </div>

            <div className="featured-stats stats stats-vertical md:stats-horizontal border border-base-300 w-full md:w-auto hidden sm:flex sm:flex-wrap">
              {isLoading && <div className="skeleton h-24 w-full"></div>}
              {data?.trip_values?.map((trip_value, index) => {
                if (trip_value.operator_trip_field.featured) {
                  const { label, name } = trip_value.operator_trip_field.trip_field;
                  return (
                    <div key={index} className="stat border-base-300">
                      <div className="stat-title">{label || name}</div>
                      <div className="stat-value">
                        {trip_value.value === "true" ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="sm"
                            className="text-success"
                          />
                        ) : trip_value.value === "false" ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            size="sm"
                            className="text-error"
                          />
                        ) : (
                          trip_value.value
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>

            <div
              className={`w-full mt-4 ${currentTab === "Stops" ? "block" : "hidden sm:block"}`}
            >
              {isLoading && <div className="skeleton h-96 w-full"></div>}
              {data && (
                <TripLocationsTable
                  trip={data}
                  isLoading={isLoading}
                  operator_short_name={operator_short_name}
                  service_date={service_date}
                  operatorTripLocationFields={operatorTripLocationFields}
                />
              )}
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div
                className={`${isLoading && "skeleton min-h-40"} col-span-1 mt-4 ${currentTab === "Crew" ? "block" : "hidden sm:block"}`}
              >
                {data && (
                  <div className="card card-compact bg-base-100 border border-base-300 prose">
                    <div className="card-header py-2 px-4 border-b">
                      <div className="flex justify-between">
                        <h3 className="m-0">Assigned Crew</h3>
                      </div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="table table-sm m-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Job</th>
                            <th>From/To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.trip_crew?.length < 1 && (
                            <tr>
                              <td colSpan={2} className="italic">
                                No crew assigned
                              </td>
                            </tr>
                          )}
                          {data?.trip_crew?.map((member, i) => {
                            return (
                              <tr key={i}>
                                <td>{member?.name}</td>
                                <td>{member?.job_assignment}</td>
                                <td>
                                  {member?.from_location?.code}
                                  {" - "}
                                  {member?.to_location?.code}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>

              <div
                id="target-div"
                ref={targetDivRef}
                className={`${isLoading && "skeleton min-h-40"} col-span-1 mt-4 sm:mt-0 lg:mt-4 ${currentTab === "Vehicles" ? "block" : "hidden sm:block"}`}
              >
                {data && (
                  <VehicleFormation
                    trip={data}
                    operator_short_name={operator_short_name}
                    service_date={service_date}
                    setShowVehicleFormationDrawer={setShowVehicleFormationDrawer}
                    showVehicleFormationDrawer={showVehicleFormationDrawer}
                  />
                )}
              </div>
            </div>

            <div
              className={`w-full mt-4 ${currentTab === "Occurrences" ? "block" : "hidden sm:block"}`}
            >
              {isLoading && <div className="skeleton h-80 w-full"></div>}
              {data && (
                <Occurrences
                  trip={data}
                  service_date={service_date}
                  operator_short_name={operator_short_name}
                  occurrenceDrawer={occurrenceDrawer}
                  setOccurrenceDrawer={setOccurrenceDrawer}
                />
              )}
            </div>

            <div
              className={`${isLoading && "skeleton min-h-80"} w-full mt-4 hidden sm:block`}
            >
              {data && (
                <div className="card card-compact bg-base-100 border border-base-300 prose">
                  <div className="card-header py-2 px-4 border-b">
                    <div className="flex justify-between">
                      <h3 className="m-0">Penalties/Incentives</h3>
                    </div>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table m-0">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Classification</th>
                          <th>Sub-Type</th>
                          <th>Amount</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={2} className="italic">
                            No penalties/incentives found
                          </td>
                        </tr>
                        {/* <tr>
                        <td>Penalty</td>
                        <td>Performance</td>
                        <td>LP2</td>
                        <td>$4,500</td>
                        <td className="text-center">
                          <div className="badge badge-sm badge-success">Excused</div>
                        </td>
                        <td className="text-center">
                          <button className="btn btn-sm btn-outline btn-primary">
                            Reinstate
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Penalty</td>
                        <td>Short Crew</td>
                        <td></td>
                        <td>$500</td>
                        <td className="text-center"></td>
                        <td className="text-center">
                          <button className="btn btn-sm btn-outline btn-primary">
                            Excuse
                          </button>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-12 xl:col-span-4">
            <div className="lg:grid lg:grid-cols-2 xl:block gap-4">
              <div
                className={`${isLoading && "skeleton min-h-40"} lg:col-span-1 ${currentTab === "Info" ? "block" : "hidden sm:block"}`}
              >
                {data && (
                  <TripParameters
                    trip={data}
                    operator_short_name={operator_short_name}
                    tripParametersDrawerIsOpen={tripParametersDrawerIsOpen}
                    setTripParametersDrawerIsOpen={setTripParametersDrawerIsOpen}
                    service_date={service_date}
                  />
                )}
              </div>

              <div
                className={`${isLoading && "hidden"} lg:col-span-1 mt-4 lg:mt-0 xl:mt-4 hidden sm:block`}
              >
                {data && (
                  <div className="hidden card card-compact bg-base-100 border border-base-300 prose">
                    <div className="card-header py-2 px-4 border-b">
                      <div className="flex justify-between">
                        <h3 className="m-0">Mass Notifications</h3>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        role="tablist"
                        className="tabs tabs-boxed bg-[#E5F6F9] p-2"
                      >
                        <input
                          type="radio"
                          name="notifications_tabs"
                          role="tab"
                          className="tab"
                          aria-label="All Pages"
                          checked
                          onChange={() => {}}
                        />
                        <div
                          role="tabpanel"
                          className="tab-content bg-base-100 mt-2"
                        >
                          <div className="overflow-x-auto">
                            <table className="table table-sm m-0">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th
                                    style={{
                                      width: "33%",
                                    }}
                                  >
                                    Timestamp
                                  </th>
                                  <th>Message</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={2} className="italic">
                                    No notifications
                                  </td>
                                </tr>
                                {/* <tr>
                                <td>451256</td>
                                <td>
                                  <span className="text-error">6:35 AM</span>{" "}
                                  <span className="badge badge-sm badge-info ml-2">
                                    Trip
                                  </span>
                                </td>
                                <td>
                                  Worcester Line Train 552 (6:30 am from Worcester)
                                  is operating 5-10 minutes behind schedule between
                                  Worcester and South Station due to a mechanical
                                  issue.
                                </td>
                              </tr>
                              <tr>
                                <td>451256</td>
                                <td>6:35 AM</td>
                                <td>
                                  Worcester Line Train 552 (6:30 am from Worcester)
                                  is operating 5-10 minutes behind schedule between
                                  Worcester and South Station due to a mechanical
                                  issue.
                                </td>
                              </tr>
                              <tr>
                                <td>451256</td>
                                <td>6:35 AM</td>
                                <td>
                                  Worcester Line Train 552 (6:30 am from Worcester)
                                  is operating 5-10 minutes behind schedule between
                                  Worcester and South Station due to a mechanical
                                  issue.
                                </td>
                              </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <input
                          type="radio"
                          name="notifications_tabs"
                          role="tab"
                          className="tab"
                          aria-label="T-Alerts"
                        />
                        <div role="tabpanel" className="tab-content py-4">
                          Tab content 2
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
