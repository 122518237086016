import Checkbox from "../../../Checkbox";
import SingleSelect from "../../../SingleSelect";
import NumberField from "../../../NumberField";
import TextArea from "../../../TextArea";
import DynamicOccurrenceCauseFormFields from "../DynamicOccurrenceCauseFormFields";
import DynamicFormFields from "../DynamicFormFields";
import WaiverStatusForm from "../WaiverStatusForm";
import { STATIC_CAUSES } from "../constants";

export default function NewOccurrenceForm({
  tripOccurrencePayload,
  setTripOccurrencePayload,
  handleFieldChange,
  operatorOccurrenceCauses,
  responsibleParties,
  setOccurrenceCauseFields,
  occurrenceCauseFields,
  occurrenceCauseValuesPayload,
  handleSetOccurrenceCauseValues,
  vehicleSearchPayload,
  setVehicleSearchPayload,
  searchingVehicle,
  handleVehicleLookUp,
  stationaryAssets,
  linearAssets,
  trip,
  operator_short_name,
  operatorTripOccurrenceFields,
  operatorTripOccurrenceFieldsPayload,
  handleSetOperatorTripOccurrenceFieldValues,
  isEditMode,
  originalValues,
  loadingEditState,
}) {
  return (
    <div>
      <div className="form-control p-2 border border-[#d3d4d7] rounded">
        <label className="label gap-2 justify-start cursor-pointer">
          <Checkbox
            checked={tripOccurrencePayload.primary}
            value={tripOccurrencePayload.primary}
            handleOnChange={(event) => handleFieldChange("primary", event)}
          />
          <span className="label-text">This is a primary occurrence</span>
        </label>
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Occurrence Cause <span className="text-error">*</span>
          </span>
        </label>
        <SingleSelect
          isDisabled={operatorOccurrenceCauses.isLoading}
          isLoading={operatorOccurrenceCauses.isLoading}
          placeholder="Pick one"
          useFormatGroupLabel={true}
          value={tripOccurrencePayload.operator_trip_occurrence_cause_id}
          handleOnChange={(operator_trip_occurrence_cause_id) => {
            setTripOccurrencePayload((prevState) => ({
              ...prevState,
              stationary_asset: "",
              linear_asset_id: "",
              start: "",
              end: "",
              vehicle: "",
            }));
            setOccurrenceCauseFields(
              operator_trip_occurrence_cause_id?.value?.occurrence_cause_fields
            );
            handleFieldChange("operator_trip_occurrence_cause_id", {
              value: operator_trip_occurrence_cause_id?.value?.occurrence_cause,
              label: operator_trip_occurrence_cause_id?.label,
            });
          }}
          options={
            Array.isArray(operatorOccurrenceCauses?.data) &&
            Object.values(
              operatorOccurrenceCauses?.data?.reduce((acc, item) => {
                const type = item.occurrence_cause.type;
                if (!acc[type]) {
                  acc[type] = {
                    label: type,
                    options: [],
                  };
                }
                acc[type].options.push({
                  value: item,
                  label: item.occurrence_cause.name,
                });
                return acc;
              }, {})
            )
          }
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Responsible Party <span className="text-error">*</span>
          </span>
        </label>
        <SingleSelect
          isDisabled={responsibleParties.isLoading}
          isLoading={responsibleParties.isLoading}
          placeholder="Pick one"
          value={tripOccurrencePayload.responsible_party_id}
          handleOnChange={(responsible_party_id) =>
            handleFieldChange("responsible_party_id", responsible_party_id)
          }
          options={
            responsibleParties?.data &&
            Array.isArray(responsibleParties?.data) &&
            [...STATIC_CAUSES, ...responsibleParties.data]?.map((party) => {
              return { value: party.id, label: party.name };
            })
          }
        />
      </div>

      {/* static occurrence cause fields based on cause?  */}
      {loadingEditState ? (
        <div className="skeleton min-h-40 col-span-1 mt-4"></div>
      ) : (
        <DynamicOccurrenceCauseFormFields
          occurrencePayload={tripOccurrencePayload}
          handleFieldChange={handleFieldChange}
          setOccurrencePayload={setTripOccurrencePayload}
          vehicleSearchPayload={vehicleSearchPayload}
          setVehicleSearchPayload={setVehicleSearchPayload}
          searchingVehicle={searchingVehicle}
          handleVehicleLookUp={handleVehicleLookUp}
          stationaryAssets={stationaryAssets}
          linearAssets={linearAssets}
          trip={trip}
          occurrenceCause={
            tripOccurrencePayload.operator_trip_occurrence_cause_id &&
            tripOccurrencePayload.operator_trip_occurrence_cause_id
          }
          selectedOperator={operator_short_name}
        />
      )}

      {/* occurrence cause fields linked from occurrence causes */}
      <DynamicFormFields
        fields={occurrenceCauseFields}
        payload={occurrenceCauseValuesPayload}
        handleFieldChange={handleSetOccurrenceCauseValues}
      />

      <hr className="my-3" />

      <div className="form-control max-w-20">
        <label className="label">
          <span className="label-text">
            Delay Minutes <span className="text-error">*</span>
          </span>
        </label>
        <NumberField
          placeholder="00"
          value={tripOccurrencePayload.delay_minutes}
          handleOnChange={(event) =>
            handleFieldChange("delay_minutes", Number(event.target.value))
          }
        />
      </div>

      {/* dynamic operator trip occurrence fields */}
      <DynamicFormFields
        fields={operatorTripOccurrenceFields?.data?.map((field) => {
          return field?.trip_occurrence_field;
        })}
        payload={operatorTripOccurrenceFieldsPayload}
        handleFieldChange={handleSetOperatorTripOccurrenceFieldValues}
      />

      {/* WAIVER STATUS */}

      <WaiverStatusForm
        isEditMode={isEditMode}
        originalValues={originalValues}
        payload={tripOccurrencePayload}
        setPayload={setTripOccurrencePayload}
        handleFieldChange={handleFieldChange}
      />

      <div className="form-control">
        <label className="label">
          <span className="label-text">
            Comments
            <span className="text-error"> *</span>
          </span>
        </label>
        <TextArea
          value={tripOccurrencePayload.comments}
          handleOnChange={(event) =>
            handleFieldChange("comments", event.target.value)
          }
        />
      </div>
    </div>
  );
}
