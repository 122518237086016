import { DynamicFieldValue } from "../types/forms";

export const formatFieldValues = (fieldValuePayload: DynamicFieldValue) => {
  const formattedFieldValues = [];

  const fieldValueJson = JSON.parse(JSON.stringify(fieldValuePayload));

  for (const fieldName in fieldValueJson) {
    const value = {
      field: fieldName,
      value: fieldValueJson[fieldName],
    };

    if (value.value !== "" && value.value !== null) {
      formattedFieldValues.push(value);
    }
  }

  return formattedFieldValues;
};
