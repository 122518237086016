export default function RerouteServedConnectorOut({ isSelected }) {
  return (
    <svg
      width="30"
      height="35"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 13L22 6.5L22 4.76837e-07"
        stroke={isSelected ? "green" : "#00AAC3"}
        strokeWidth="4"
      />
      <path d="M22 40V19.5V13" stroke="#808080" strokeWidth="2" />
      <path
        d="M22 13L2 33"
        stroke={isSelected ? "green" : "#00AAC3"}
        strokeWidth="4"
      />
      <circle cx="22" cy="13" r="5" fill={isSelected ? "green" : "#00AAC3"} />
    </svg>
  );
}
