import SingleSelect from "../../SingleSelect";

const StationaryAssetsField = ({
  occurrencePayload,
  setOccurrencePayload,
  stationaryAssets,
  handleFieldChange,
  linearAssetSelectionRequired,
  isRequired,
  manageOccurrences,
}) => {
  const sortedStationaryAssets = Array.isArray(stationaryAssets.data)
    ? stationaryAssets.data.sort((a, b) => {
        if (a.sequence === b.sequence) {
          return a.subsequence - b.subsequence;
        }
        return a.sequence - b.sequence;
      })
    : [];

  return (
    <div className="form-control">
      {manageOccurrences ? (
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">
              {" "}
              <span className="label-text">
                Stationary Asset
                {isRequired && <span className="text-error"> *</span>}
              </span>
            </label>
          </div>
          <div className="w-60">
            <SingleSelect
              isDisabled={
                stationaryAssets.isLoading ||
                (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id)
              }
              isLoading={stationaryAssets.isLoading}
              placeholder="Pick one"
              value={occurrencePayload.stationary_asset}
              handleOnChange={(stationary_asset) =>
                handleFieldChange("stationary_asset", stationary_asset)
              }
              useFormatGroupLabel={true}
              options={sortedStationaryAssets.map((stationaryAsset) => {
                const options = stationaryAsset?.assets?.map((asset) => {
                  return { value: asset.id, label: asset.name };
                });
                return {
                  label: stationaryAsset.location_name,
                  options: options,
                };
              })}
            />
          </div>
        </div>
      ) : (
        <>
          <label className="label">
            <span className="label-text">Stationary Asset</span>
            {isRequired && <span className="text-error">*</span>}
          </label>
          <SingleSelect
            isDisabled={
              stationaryAssets.isLoading ||
              (linearAssetSelectionRequired && !occurrencePayload.linear_asset_id)
            }
            isLoading={stationaryAssets.isLoading}
            placeholder="Pick one"
            value={occurrencePayload.stationary_asset}
            handleOnChange={(stationary_asset) =>
              handleFieldChange("stationary_asset", stationary_asset)
            }
            useFormatGroupLabel={true}
            options={sortedStationaryAssets.map((stationaryAsset) => {
              const options = stationaryAsset?.assets?.map((asset) => {
                return { value: asset.id, label: asset.name };
              });
              return {
                label: stationaryAsset.location_name,
                options: options,
              };
            })}
          />
        </>
      )}
    </div>
  );
};

export default StationaryAssetsField;
