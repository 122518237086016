export default function RerouteStraightConnector({ gray, isSelected }) {
  function Path() {
    if (gray) {
      return <path d="M8 39L8 -1.19209e-06" stroke="#808080" strokeWidth="2" />;
    } else if (isSelected) {
      return <path d="M8 39L8 -1.19209e-06" stroke="green" strokeWidth="4" />;
    } else {
      return <path d="M8 39L8 -1.19209e-06" stroke="#00AAC3" strokeWidth="4" />;
    }
  }

  return (
    <svg
      width="16"
      height={gray ? "33" : "36"}
      viewBox={`0 0 16 ${gray ? "33" : "36"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path />
    </svg>
  );
}
