import { useQueryClient } from "@tanstack/react-query";
import { ResidualTripOccurrencePayload } from "../types/forms";

interface UsePostResidualTripOccurrenceParams {
  operator_short_name: string;
  service_date: string;
  trip: any;
  setPostingOccurrence: (posting: boolean) => void;
  resetFormState: () => void;
  setOccurrenceDrawer: (open: boolean) => void;
  setTripOccurrenceType: (type: string) => void;
  toast: any;
  postResidualTripOccurrence: (
    operator_short_name: string,
    service_date: string,
    trip_name: string,
    payload: any
  ) => Promise<any>;
}

export const usePostResidualTripOccurrence = ({
  operator_short_name,
  service_date,
  trip,
  setPostingOccurrence,
  resetFormState,
  setOccurrenceDrawer,
  setTripOccurrenceType,
  toast,
  postResidualTripOccurrence,
}: UsePostResidualTripOccurrenceParams) => {
  const queryClient = useQueryClient();

  const postResidualTripOccurrenceHandler = async (
    residualTripOccurrencePayload: ResidualTripOccurrencePayload
  ) => {
    const cleanObject = (obj: any) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== "" && value !== null && value !== undefined
        )
      );
    };

    const cleanedPayload = {
      occurrence: {},
      trip_occurrence: cleanObject(residualTripOccurrencePayload.trip_occurrence),
    };

    setPostingOccurrence(true);
    try {
      await postResidualTripOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        cleanedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setTripOccurrenceType("new");
          setOccurrenceDrawer(false);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              return {
                ...oldData,
                trip_occurrences: [res, ...trip.trip_occurrences],
              };
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  return { postResidualTripOccurrenceHandler };
};
