import SingleSelect from "../../SingleSelect";
import NumberField from "../../NumberField";

const LinearAssetField = ({
  occurrencePayload,
  setOccurrencePayload,
  linearAssets,
  handleFieldChange,
  showStartAndEnd,
  isRequired,
  selectedOperator,
  manageOccurrences,
}) => (
  <>
    <div className="form-control">
      {manageOccurrences ? (
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <label className="font-bold">
              {" "}
              <span className="label-text">
                Linear Asset
                {isRequired && <span className="text-error"> *</span>}
              </span>
            </label>
          </div>
          <div className="w-60">
            <SingleSelect
              isDisabled={linearAssets.isLoading || !selectedOperator}
              isLoading={linearAssets.isLoading}
              placeholder="Pick one"
              value={occurrencePayload.linear_asset_id}
              handleOnChange={(linear_asset) => {
                setOccurrencePayload((prevState) => ({
                  ...prevState,
                  linear_asset_start: "",
                  linear_asset_end: "",
                }));
                handleFieldChange("linear_asset_id", linear_asset);
                handleFieldChange(
                  "linear_asset_start",
                  linear_asset?.value?.start_measurement
                );
                handleFieldChange(
                  "linear_asset_end",
                  linear_asset?.value?.end_measurement
                );
              }}
              options={
                Array.isArray(linearAssets.data) &&
                linearAssets?.data?.map((linearAsset) => {
                  return {
                    label: linearAsset.name,
                    value: linearAsset,
                  };
                })
              }
            />
          </div>
        </div>
      ) : (
        <>
          <label className="label">
            <span className="label-text">
              Linear Asset {isRequired && <span className="text-error">*</span>}
            </span>
          </label>
          <SingleSelect
            isDisabled={linearAssets.isLoading || !selectedOperator}
            isLoading={linearAssets.isLoading}
            placeholder="Pick one"
            value={occurrencePayload.linear_asset_id}
            handleOnChange={(linear_asset) => {
              setOccurrencePayload((prevState) => ({
                ...prevState,
                linear_asset_start: "",
                linear_asset_end: "",
              }));
              handleFieldChange("linear_asset_id", linear_asset);
              handleFieldChange(
                "linear_asset_start",
                linear_asset?.value?.start_measurement
              );
              handleFieldChange(
                "linear_asset_end",
                linear_asset?.value?.end_measurement
              );
            }}
            options={
              Array.isArray(linearAssets.data) &&
              linearAssets?.data?.map((linearAsset) => {
                return {
                  label: linearAsset.name,
                  value: linearAsset,
                };
              })
            }
          />
        </>
      )}
    </div>
    {showStartAndEnd && (
      <div className="flex justify-start gap-4">
        <div>
          <label className="label">
            <span className="label-text">
              Start {isRequired && <span className="text-error">*</span>}
            </span>
          </label>
          <NumberField
            className="max-w-20"
            placeholder="00"
            min={occurrencePayload?.linear_asset_id?.value?.start_measurement || 0}
            max={occurrencePayload?.linear_asset_id?.value?.end_measurement - 1 || 0}
            disabled={!occurrencePayload.linear_asset_id}
            value={occurrencePayload.linear_asset_start}
            handleOnChange={(event) => {
              if (
                event.target.value >
                occurrencePayload?.linear_asset_id?.value?.end_measurement - 1
              ) {
                handleFieldChange(
                  "linear_asset_start",
                  occurrencePayload?.linear_asset_id?.value?.end_measurement - 1
                );
              } else if (
                event.target.value <
                occurrencePayload?.linear_asset_id?.value?.start_measurement
              ) {
                handleFieldChange(
                  "linear_asset_start",
                  occurrencePayload?.linear_asset_id?.value?.start_measurement
                );
              } else if (event.target.value >= occurrencePayload.linear_asset_end) {
                return;
              } else {
                handleFieldChange("linear_asset_start", event.target.value);
              }
            }}
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text">
              End {isRequired && <span className="text-error">*</span>}
            </span>
          </label>
          <NumberField
            placeholder="00"
            disabled={!occurrencePayload.linear_asset_id}
            min={
              occurrencePayload?.linear_asset_id?.value?.start_measurement + 1 || 0
            }
            max={occurrencePayload?.linear_asset_id?.value?.end_measurement || 0}
            className="max-w-20"
            value={occurrencePayload.linear_asset_end}
            handleOnChange={(event) => {
              if (
                event.target.value >
                occurrencePayload?.linear_asset_id?.value?.end_measurement
              ) {
                handleFieldChange(
                  "linear_asset_end",
                  occurrencePayload?.linear_asset_id?.value?.end_measurement
                );
              } else if (
                event.target.value <=
                occurrencePayload?.linear_asset_id?.value?.start_measurement + 1
              ) {
                handleFieldChange(
                  "linear_asset_end",
                  occurrencePayload?.linear_asset_id?.value?.start_measurement + 1
                );
              } else if (
                event.target.value <= occurrencePayload.linear_asset_start
              ) {
                return;
              } else {
                handleFieldChange("linear_asset_end", event.target.value);
              }
            }}
          />
        </div>
      </div>
    )}
  </>
);

export default LinearAssetField;
