import { useQueryClient } from "@tanstack/react-query";
import { ResidualTripOccurrencePayloadEditMode } from "../types/forms";

interface UseEditResidualTripOccurrenceParams {
  operator_short_name: string;
  service_date: string;
  trip: any;
  setPostingOccurrence: (posting: boolean) => void;
  resetFormState: () => void;
  setOccurrenceDrawer: (open: boolean) => void;
  setTripOccurrenceType: (type: string) => void;
  toast: any;
  editTripOccurrence: (
    operator_short_name: string,
    service_date: string,
    trip_name: string,
    payload: any
  ) => Promise<any>;
}

export const useEditResidualTripOccurrence = ({
  operator_short_name,
  service_date,
  trip,
  setPostingOccurrence,
  resetFormState,
  setOccurrenceDrawer,
  setTripOccurrenceType,
  toast,
  editTripOccurrence,
}: UseEditResidualTripOccurrenceParams) => {
  const queryClient = useQueryClient();

  const editResidualTripOccurrenceHandler = async (
    residualTripOccurrencePayload: ResidualTripOccurrencePayloadEditMode
  ) => {
    const cleanObject = (obj: any) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== "" && value !== null && value !== undefined
        )
      );
    };

    const cleanedPayload = {
      occurrence: {},
      trip_occurrence: cleanObject(residualTripOccurrencePayload.trip_occurrence),
    };

    setPostingOccurrence(true);
    try {
      await editTripOccurrence(
        operator_short_name,
        service_date,
        trip.trip_name,
        cleanedPayload
      ).then((res) => {
        if (!res.error) {
          resetFormState();
          setTripOccurrenceType("new");
          setOccurrenceDrawer(false);
          toast.success(`Trip ${trip?.trip_name} saved`);
          queryClient.setQueryData(
            ["trip", operator_short_name, service_date, trip.trip_name],
            (oldData) => {
              const updatedTripOccurrences = oldData.trip_occurrences.map(
                (trip_occurrence) => {
                  if (trip_occurrence.id === res.id) {
                    return res;
                  }
                  return trip_occurrence;
                }
              );
              return {
                ...oldData,
                trip_occurrences: updatedTripOccurrences,
              };
            }
          );
        }
        setPostingOccurrence(false);
      });
    } catch (error) {
      console.log(error);
      setPostingOccurrence(false);
    }
  };

  return {
    editResidualTripOccurrenceHandler,
  };
};
