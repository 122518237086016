import { WaiverStatus } from "./utils";

interface TripOccurrence {
  occurrence?: {
    occurrence_cause?: string;
    location?: {
      name?: string;
    };
  };
  from_location?: string;
  delay_minutes?: number;
  operator_trip_occurrence_cause?: string;
  waiver_status?: string;
}

interface TableRowMobileProps {
  trip_occurrence: TripOccurrence;
  index: number;
}

function parseTripOccurrence(trip_occurrence: TripOccurrence): string {
  const occurrence =
    trip_occurrence?.occurrence ||
    trip_occurrence?.parent_trip_occurrence?.occurrence;
  const cause = occurrence?.occurrence_cause || "";
  const location = occurrence?.location?.name || "";
  return `${cause} at ${location}`;
}

const TableRowMobile: React.FC<TableRowMobileProps> = ({ trip_occurrence }) => {
  const { delay_minutes, waiver_status } = trip_occurrence || {};

  return (
    <div className="px-4 py-2 border-b">
      <div className="flex justify-between items-center">
        <div className="font-bold">{delay_minutes} Mins</div>
        <WaiverStatus waiver_status={waiver_status} />
      </div>
      <div className="text-sm">{parseTripOccurrence(trip_occurrence)}</div>
    </div>
  );
};

export default TableRowMobile;
