export default function RerouteUnserved({ gray, isSelected }) {
  const lineColor = () => {
    if (gray) {
      return "#808080";
    } else if (isSelected) {
      return "green";
    } else {
      return "#00AAC3";
    }
  };

  return (
    <svg
      width="16"
      height="32"
      viewBox="0 0 16 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8"
        y1="32"
        x2="8"
        stroke={lineColor()}
        strokeWidth={gray ? "2" : "4"}
      />
      <circle
        cx="8"
        cy="16"
        r="4"
        fill="white"
        stroke={lineColor()}
        strokeWidth="2"
      />
    </svg>
  );
}
