export default function RerouteServedConnectorIn({ isSelected }) {
  return (
    <svg
      width="30"
      height="35"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 40L22 15.5L22 6" stroke="#808080" strokeWidth="2" />
      <path
        d="M22 35V28.5V22"
        stroke={isSelected ? "green" : "#00AAC3"}
        strokeWidth="4"
      />
      <circle cx="22" cy="22" r="5" fill={isSelected ? "green" : "#00AAC3"} />
      <path
        d="M22 22L2 2"
        stroke={isSelected ? "green" : "#00AAC3"}
        strokeWidth="4"
      />
    </svg>
  );
}
