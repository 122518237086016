import { useQueryClient } from "@tanstack/react-query";
import ModalWrapper from "../ModalWrapper";
import LoadingSpinner from "../../icons/LoadingSpinner";

function SignOffModal({
  setSignOffModalIsOpen,
  handleSignOffTrip,
  signingOffTrip,
  operator_short_name,
  service_date,
  trip_name,
}) {
  const queryClient = useQueryClient();

  const trip = queryClient.getQueryData([
    "trip",
    operator_short_name,
    service_date,
    trip_name,
  ]);

  return (
    <>
      <h3 className="mt-0">Sign Off on Trip</h3>

      <p>
        Are you sure you want to {trip?.signed_off ? "un" : ""}sign off
        <span className="font-bold"> Trip {trip_name}</span>?
      </p>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-outline"
          onClick={() => setSignOffModalIsOpen(false)}
        >
          Cancel
        </button>
        <button
          disabled={signingOffTrip}
          className="btn btn-error"
          onClick={() => handleSignOffTrip()}
        >
          {trip?.signed_off ? "Un-Sign Off" : "Sign Off"}
          {signingOffTrip && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}

export default function SignOff({
  tripContext,
  trip_name,
  operator_short_name,
  service_date,
  viewingTripDetail,
  signOffModalIsOpen,
  handleSignOffTrip,
  setSignOffModalIsOpen,
  signingOffTrip,
}) {
  const SignOffButton = () => {
    if (tripContext?.isLoading) {
      return (
        <button className="btn btn-error" disabled>
          <LoadingSpinner />
        </button>
      );
    }
    return (
      <button
        className={`btn ${tripContext?.signed_off ? "btn-success" : "btn-error"}`}
        onClick={() => setSignOffModalIsOpen(true)}
      >
        {tripContext?.signed_off ? "Signed Off" : "Sign Off"}
      </button>
    );
  };

  return (
    <>
      {viewingTripDetail && <SignOffButton />}
      {signOffModalIsOpen && (
        <ModalWrapper modalIsOpen={signOffModalIsOpen}>
          <SignOffModal
            trip_name={trip_name}
            handleSignOffTrip={handleSignOffTrip}
            setSignOffModalIsOpen={setSignOffModalIsOpen}
            signingOffTrip={signingOffTrip}
            operator_short_name={operator_short_name}
            service_date={service_date}
          />
        </ModalWrapper>
      )}
    </>
  );
}
