export default function RerouteDestination({ isSelected }) {
  return (
    <svg
      width="16"
      height="32"
      viewBox="0 0 16 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16L8 6.5L8 4.76837e-07"
        stroke={isSelected ? "green" : "#00AAC3"}
        strokeWidth="4"
      />
      <circle
        cx="8"
        cy="8"
        r="8"
        transform="rotate(-180 8 13)"
        fill={isSelected ? "green" : "#00AAC3"}
      />
    </svg>
  );
}
