//based on TRMS DB Design, this component's JS true/false boolean will need to be converted
//to a string "TRUE" or "FALSE" before being sent to the backend if used in a form

export default function Checkbox({
  handleOnChange,
  value,
  toggle,
  defaultChecked,
  disabled,
  checked,
  string,
}) {
  // Convert the incoming value to a boolean if the string prop is true
  const handleValue = (value) => {
    if (string) {
      return value === "TRUE";
    } else {
      return value;
    }
  };

  // Convert the outgoing value to a string if the string prop is true
  const handleChange = (event) => {
    if (string) {
      handleOnChange(event.target.checked ? "TRUE" : "FALSE");
    } else {
      handleOnChange(event.target.checked);
    }
  };

  return (
    <input
      checked={handleValue(checked)}
      disabled={disabled}
      defaultChecked={defaultChecked}
      type="checkbox"
      value={handleValue(value)}
      className={
        toggle
          ? "toggle toggle-primary toggle-sm lg:toggle-md rounded-full"
          : "checkbox checkbox-primary checkbox-xs"
      }
      onChange={handleChange}
    />
  );
}
