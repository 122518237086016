import { UserContext } from "../../../utils/Contexts";
import { useContext } from "react";

export default function WaiverStatusForm({
  payload,
  isEditMode,
  originalValues,
  setPayload,
  handleFieldChange,
  formType,
}) {
  const { currentUserContext } = useContext(UserContext);

  //if false, request button will be disabled
  const canRequestWaiver =
    !currentUserContext?.waiver_permissions?.can_request_waiver ||
    (isEditMode &&
      originalValues.waiver_status &&
      (originalValues?.waiver_status === "approved" ||
        originalValues?.waiver_status === "denied"));

  //if false, approved and denied buttons will be disabled
  const canRespondToWaiver =
    !currentUserContext?.waiver_permissions?.can_respond_to_waiver ||
    (isEditMode &&
      originalValues.waiver_status &&
      originalValues?.waiver_status === "Not Requested");

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">
          Waiver Status
          <span className="text-error"> *</span>
        </span>
      </label>
      <div className="join join-horizontal">
        <button
          disabled={canRequestWaiver}
          className={`btn btn-primary ${payload?.waiver_status === "requested" ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "requested") {
              handleFieldChange("waiver_status", "Not Requested", formType);
            } else {
              handleFieldChange("waiver_status", "requested", formType);
            }
          }}
        >
          Requested
        </button>
        <button
          disabled={canRespondToWaiver}
          className={`btn btn-primary ${payload?.waiver_status === "approved" ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "approved") {
              handleFieldChange("waiver_status", "Not Requested", formType);
            } else {
              handleFieldChange("waiver_status", "approved", formType);
            }
          }}
        >
          Approved
        </button>
        <button
          disabled={canRespondToWaiver}
          className={`btn btn-primary ${payload?.waiver_status === "denied" ? "btn-fill" : "btn-outline"} join-item w-1/3`}
          onClick={(event) => {
            event.preventDefault();
            if (payload?.waiver_status === "denied") {
              handleFieldChange("waiver_status", "Not Requested", formType);
            } else {
              handleFieldChange("waiver_status", "denied", formType);
            }
          }}
        >
          Denied
        </button>
      </div>
    </div>
  );
}
