import LoadingSpinner from "../../../icons/LoadingSpinner";

export default function OriginateModal({
  originateModalIsOpen,
  setOriginateModalIsOpen,
  handleOriginateTripLocation,
  originatingTripLocation,
}) {
  const tripLocationToOriginate = { ...originateModalIsOpen };
  return (
    <>
      <h3 className="mt-0">Originate</h3>

      <p>
        Are you sure you want to originate the trip at{" "}
        <span className="font-bold">{tripLocationToOriginate?.location?.name}</span>?
      </p>

      <div className="modal-action justify-start">
        <button
          className="btn btn-primary btn-outline"
          onClick={() => setOriginateModalIsOpen(false)}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleOriginateTripLocation(tripLocationToOriginate?.id)}
          disabled={originatingTripLocation}
        >
          Originate
          {originatingTripLocation && <LoadingSpinner />}
        </button>
      </div>
    </>
  );
}
