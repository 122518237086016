import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import toast from "react-hot-toast";

export async function loader() {
  return {};
}

export default function Report() {
  const { instance: msalInstance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [reportInfo, setReportInfo] = useState<any>({});

  useEffect(() => {
    (async () => {
      try {
        await msalInstance
        .acquireTokenSilent({
          scopes: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
        })
        .then((res) => {
          setAccessToken(res.accessToken);
        })

        const reportInfoUrl = 'https://api.powerbi.com/v1.0/myorg/groups/b344b9b2-9d51-4cbf-861e-d19a0d02e9da/reports/20339013-20f1-44d9-bafc-e35705cc606b'

        const response = await fetch(reportInfoUrl, { 
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },
          method: 'GET'
        });

        const reportInfo = await response.json();
        setReportInfo(reportInfo);
      } catch (error) {
        console.error(error);
        toast.error("Unable to load report.");
      }
    })();
  })

  return <PowerBIEmbed
    embedConfig = {{
      type: 'report',   // Supported types: report, dashboard, tile, visual, qna and paginated report
      embedUrl: reportInfo.embedUrl || undefined,
      accessToken: accessToken || undefined,    // Keep as empty string, null or undefined
      tokenType: models.TokenType.Aad
    }}
    cssClassName = { 'h-screen' }
  />
}