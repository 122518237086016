export default function StopTypeStop({ originatedAt, terminatedAt }) {
  return (
    <svg
      width="16"
      height="48"
      viewBox="0 0 16 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8"
        y1="0"
        x2="8"
        y2="24"
        stroke={originatedAt ? "red" : "#06BA63"}
        strokeWidth="2"
      />
      <line
        x1="8"
        y1="24"
        x2="8"
        y2="48"
        stroke={terminatedAt ? "red" : "#06BA63"}
        strokeWidth="2"
      />
      <circle cx="8" cy="24" r="5" fill="#06BA63" />
    </svg>
  );
}
