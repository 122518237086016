import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromArc,
  faArrowRightToArc,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import StopTypeOrigin from "../../../icons/StopTypeOrigin";
import StopTypeDestination from "../../../icons/StopTypeDestination";
import StopTypeStop from "../../../icons/StopTypeStop";
import StopTypeCp from "../../../icons/StopTypeCp";
import StopTypeBypass from "../../../icons/StopTypeBypass";
import StopTypeFlag from "../../../icons/StopTypeFlag";
import { formatInTimeZone, format } from "date-fns-tz";
import Checkbox from "../../Checkbox";
import SingleSelect from "../../SingleSelect";
import DateTimePicker from "../../DateTimePicker";
import NumberField from "../../NumberField";
import TextField from "../../TextField";
import { handlePotentialBooleanStrings } from "../../../utils/helperFunctions";
import { COLUMNS } from "./constants";
import { ServedFlag } from "./ServedFlag";

export default function TableRow({
  trip,
  trip_location,
  index,
  editMode,
  locationsToBeEdited,
  setLocationsToBeEdited,
  operatorTripLocationFields,
  visibleTableColumns,
  setOriginateModalIsOpen,
  setTerminateModalIsOpen,
}) {
  const {
    location,
    actual_is_stopping,
    scheduled_arrival,
    scheduled_departure,
    trip_location_values,
    id,
    actual_location,
  } = trip_location;

  const dynamicColumns = [...visibleTableColumns].filter(
    (column) => !COLUMNS.some((staticColumn) => column.name === staticColumn.name)
  );

  const columnIsVisible = (columnName) => {
    return visibleTableColumns.some(
      (col) => col.name === columnName && col.visible === true
    );
  };

  const originatedAt =
    !trip.trip_locations[index - 1]?.actual_location &&
    !trip.trip_locations[index - 1]?.actual_is_stopping;

  const terminatedAt =
    !trip.trip_locations[index + 1]?.actual_location &&
    !trip.trip_locations[index + 1]?.actual_is_stopping;

  const firstStop = index === 0;
  const lastStop = index === trip.trip_locations.length - 1;

  const bubbleDiagramIcon = () => {
    if (index === 0 && !actual_location) {
      return <StopTypeOrigin red />;
    }
    if (index === 0) {
      return <StopTypeOrigin />;
    }
    if (index === trip.trip_locations.length - 1 && !actual_location) {
      return <StopTypeDestination red />;
    }
    if (index === trip.trip_locations.length - 1) {
      return <StopTypeDestination />;
    }
    if (
      actual_location &&
      trip_location.location.allow_stops &&
      trip_location.actual_is_stopping
    ) {
      return (
        <StopTypeStop originatedAt={originatedAt} terminatedAt={terminatedAt} />
      );
    }
    if (
      actual_location &&
      trip_location.location.allow_stops &&
      !trip_location.actual_is_stopping
    ) {
      return <StopTypeCp originatedAt={originatedAt} terminatedAt={terminatedAt} />;
    }
    if (actual_location && !trip_location.location.allow_stops) {
      return <StopTypeBypass />;
    }
    if (!actual_location) {
      return <StopTypeFlag />;
    }
  };

  return (
    <tr className="hover:bg-[#E5F6F9] border-0">
      <td className="pr-0">{bubbleDiagramIcon()}</td>
      {columnIsVisible("location") && <td className="truncate">{location?.name}</td>}
      {columnIsVisible("actual_is_stopping") && (
        <td>
          {!editMode ? (
            <ServedFlag trip_location={trip_location} />
          ) : (
            <Checkbox
              disabled={!location?.allow_stops || !trip_location?.actual_location}
              handleOnChange={(value) => {
                const updatedActualIsStopping = { ...locationsToBeEdited };
                if (value === actual_is_stopping) {
                  delete updatedActualIsStopping[id].actual_is_stopping;
                  if (Object.keys(updatedActualIsStopping[id]).length === 0) {
                    delete updatedActualIsStopping[id];
                  }
                } else {
                  if (!updatedActualIsStopping[id]) {
                    updatedActualIsStopping[id] = {};
                  }
                  updatedActualIsStopping[id].actual_is_stopping = {
                    value: value,
                  };
                }
                setLocationsToBeEdited(updatedActualIsStopping);
              }}
              checked={
                locationsToBeEdited[id]?.actual_is_stopping?.value ??
                actual_is_stopping
              }
              value={
                locationsToBeEdited[id]?.actual_is_stopping?.value ??
                actual_is_stopping
              }
            />
          )}
        </td>
      )}
      {columnIsVisible("scheduled_arrival") && (
        <td>
          {scheduled_arrival &&
            formatInTimeZone(scheduled_arrival, "America/New_York", "h:mm a")}
        </td>
      )}
      {columnIsVisible("scheduled_departure") && (
        <td>
          {scheduled_departure &&
            formatInTimeZone(scheduled_departure, "America/New_York", "h:mm a")}
        </td>
      )}
      {columnIsVisible("originate") && (
        <td className="text-center">
          {actual_location && !firstStop && (
            <FontAwesomeIcon
              onClick={() => setOriginateModalIsOpen(trip_location)}
              icon={faArrowRightFromArc}
              size="xl"
              className="text-primary cursor-pointer"
            />
          )}
        </td>
      )}
      {columnIsVisible("terminate") && (
        <td className="text-center">
          {actual_location && !lastStop && (
            <FontAwesomeIcon
              onClick={() => setTerminateModalIsOpen(trip_location)}
              icon={faArrowRightToArc}
              size="xl"
              className="text-error cursor-pointer"
            />
          )}
        </td>
      )}

      {!editMode &&
        dynamicColumns
          .filter((column) =>
            visibleTableColumns.some(
              (visibleColumn) =>
                visibleColumn.name === column.name && visibleColumn.visible === true
            )
          )
          .map((column) => {
            const tripLocationValue = trip_location_values.find(
              (value) => value.trip_location_field.name === column.name
            );

            if (
              tripLocationValue?.value &&
              operatorTripLocationFields?.length > 0 &&
              operatorTripLocationFields?.find(
                (field) =>
                  tripLocationValue?.operator_trip_location_field === field?.id &&
                  field?.trip_location_field?.type === "DATETIME"
              )
            ) {
              if (tripLocationValue.value === "None") {
                return <td key={column.name}>None</td>;
              }
              return (
                <td key={column.name}>
                  {format(tripLocationValue?.value, "h:mm a")}
                </td>
              );
            } else if (
              tripLocationValue?.value &&
              operatorTripLocationFields?.length > 0 &&
              operatorTripLocationFields?.find(
                (field) =>
                  tripLocationValue?.operator_trip_location_field === field?.id &&
                  field?.trip_location_field?.type === "BOOLEAN"
              )
            ) {
              return (
                <td key={column.name}>
                  {handlePotentialBooleanStrings(tripLocationValue?.value) ? (
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-error" />
                  )}
                </td>
              );
            } else if (tripLocationValue?.value) {
              return (
                <td
                  key={column.name}
                  className="truncate max-w-48"
                  title={`${tripLocationValue?.value}`}
                >
                  {tripLocationValue?.value}
                </td>
              );
            } else {
              return <td key={column.name}></td>;
            }
          })}
      {editMode &&
        operatorTripLocationFields?.length > 0 &&
        operatorTripLocationFields?.map((operatorTripLocationField) => {
          const hasAtLeastOneValue = trip.trip_locations.some((trip_location) =>
            trip_location.trip_location_values.some(
              (trip_location_value) =>
                trip_location_value.operator_trip_location_field ===
                  operatorTripLocationField.trip_location_field.id &&
                trip_location_value
            )
          );

          if (!hasAtLeastOneValue && !operatorTripLocationField.can_edit) {
            return;
          }

          const tripLocationValue = trip_location_values.find(
            (value) =>
              value?.operator_trip_location_field === operatorTripLocationField?.id
          );

          if (
            operatorTripLocationField?.trip_location_field?.type === "TEXT" &&
            operatorTripLocationField?.lookup_list_items?.length === 0
          ) {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <TextField
                  disabled={!operatorTripLocationField?.can_edit}
                  handleOnChange={(event) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (event.target.value === tripLocationValue?.value) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: event.target.value,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    (locationsToBeEdited[id]?.values &&
                      locationsToBeEdited[id]?.values[
                        operatorTripLocationField?.trip_location_field?.name
                      ]?.value) ??
                    (trip?.trip_locations
                      ?.find((x) => x?.id === id)
                      ?.trip_location_values?.find(
                        (x) =>
                          x?.operator_trip_location_field ===
                          operatorTripLocationField?.trip_location_field?.id
                      ) &&
                      trip?.trip_locations
                        ?.find((x) => x?.id === id)
                        ?.trip_location_values.find(
                          (x) =>
                            x?.operator_trip_location_field ===
                            operatorTripLocationField?.trip_location_field?.id
                        )?.value)
                  }
                />
              </td>
            );
          }

          if (operatorTripLocationField?.trip_location_field?.type === "INTEGER") {
            return (
              <td key={operatorTripLocationField?.trip_location_field?.name}>
                <NumberField
                  disabled={!operatorTripLocationField?.can_edit}
                  handleOnChange={(event) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (event.target.value === tripLocationValue?.value) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: event.target.value,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    (locationsToBeEdited[id]?.values &&
                      locationsToBeEdited[id]?.values[
                        operatorTripLocationField?.trip_location_field?.name
                      ]?.value) ??
                    (trip?.trip_locations
                      ?.find((x) => x?.id === id)
                      ?.trip_location_values?.find(
                        (x) =>
                          x?.operator_trip_location_field ===
                          operatorTripLocationField?.trip_location_field?.id
                      ) &&
                      trip?.trip_locations
                        ?.find((x) => x?.id === id)
                        ?.trip_location_values.find(
                          (x) =>
                            x?.operator_trip_location_field ===
                            operatorTripLocationField?.trip_location_field?.id
                        )?.value)
                  }
                />
              </td>
            );
          }

          if (operatorTripLocationField?.trip_location_field?.type === "BOOLEAN") {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <Checkbox
                  string={true}
                  disabled={!operatorTripLocationField?.can_edit}
                  checked={
                    locationsToBeEdited[id]?.values[
                      operatorTripLocationField?.trip_location_field?.name
                    ]?.value ?? tripLocationValue?.value
                  }
                  value={
                    locationsToBeEdited[id]?.values[
                      operatorTripLocationField?.trip_location_field?.name
                    ]?.value ?? tripLocationValue?.value
                  }
                  handleOnChange={(event) => {
                    const value = event;
                    const updatedValues = { ...locationsToBeEdited };
                    if (value === tripLocationValue?.value) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: value,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                />
              </td>
            );
          }

          if (
            operatorTripLocationField?.trip_location_field?.type === "TEXT" &&
            operatorTripLocationField?.lookup_list_items?.length > 0
          ) {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <SingleSelect
                  isDisabled={!operatorTripLocationField?.can_edit}
                  placeholder={tripLocationValue?.value}
                  options={operatorTripLocationField.lookup_list_items.map(
                    (option) => {
                      return { value: option, label: option };
                    }
                  )}
                  handleOnChange={(option, action) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (
                      action?.action === "clear" ||
                      option?.value === tripLocationValue?.value
                    ) {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: option,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    locationsToBeEdited[id]?.values &&
                    locationsToBeEdited[id]?.values[
                      operatorTripLocationField?.trip_location_field?.name
                    ]?.value
                  }
                />
              </td>
            );
          }

          if (operatorTripLocationField?.trip_location_field?.type === "DATETIME") {
            return (
              <td key={operatorTripLocationField?.trip_location_field.name}>
                <DateTimePicker
                  disabled={!operatorTripLocationField?.can_edit}
                  handleOnChange={(event) => {
                    const updatedValues = { ...locationsToBeEdited };
                    if (event === tripLocationValue?.value || event === "") {
                      delete updatedValues[id].values[
                        operatorTripLocationField?.trip_location_field?.name
                      ];
                      if (Object.keys(updatedValues[id].values).length === 0) {
                        delete updatedValues[id];
                      }
                    } else {
                      updatedValues[id] = {
                        ...updatedValues[id],
                        values: {
                          ...updatedValues[id]?.values,
                          [operatorTripLocationField?.trip_location_field?.name]: {
                            id: tripLocationValue?.id,
                            value: event,
                          },
                        },
                      };
                    }
                    setLocationsToBeEdited(updatedValues);
                  }}
                  value={
                    (locationsToBeEdited[id]?.values &&
                      locationsToBeEdited[id]?.values[
                        operatorTripLocationField?.trip_location_field?.name
                      ]?.value) ??
                    (trip?.trip_locations
                      ?.find((x) => x?.id === id)
                      ?.trip_location_values?.find(
                        (x) =>
                          x?.operator_trip_location_field ===
                          operatorTripLocationField?.id
                      ) &&
                      trip?.trip_locations
                        ?.find((x) => x?.id === id)
                        ?.trip_location_values.find(
                          (x) =>
                            x?.operator_trip_location_field ===
                            operatorTripLocationField?.id
                        )?.value)
                  }
                />
              </td>
            );
          }
        })}
    </tr>
  );
}
