export default function StopTypeBypass() {
  return (
    <svg
      width="16"
      height="48"
      viewBox="0 0 16 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="8" y1="0" x2="8" y2="48" stroke="#808080" strokeWidth="2" />
    </svg>
  );
}
