export default function StopTypeDestination({ red }) {
  return (
    <svg
      width="16"
      height="48"
      viewBox="0 0 16 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="24" r="8" fill={red ? "#E13C55" : "#06BA63"} />
      <line
        x1="8"
        y1="28"
        x2="8"
        stroke={red ? "#E13C55" : "#06BA63"}
        strokeWidth="2"
      />
    </svg>
  );
}
