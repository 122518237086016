import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import RouteStraightVert from "../../../icons/RouteStraightVert";
import RouteReverseVert from "../../../icons/RouteReverseVert";

export default function RouteCard({
  route,
  index,
  handleClickRoute,
  selectedRoute,
}) {
  const isSelected = selectedRoute === route?.path_id;
  const buttonClass = isSelected
    ? "border-success hover:bg-success hover:border-success text-base-content hover:text-base-content"
    : "btn-primary";

  return (
    <div
      onClick={() => handleClickRoute(route)}
      className={`w-44 px-1 shrink-0 rounded-lg ${isSelected ? "bg-success" : "hover:cursor-pointer hover:bg-[#E5F6F9]"}`}
    >
      <div
        className={`flex flex-col gap-5 py-3 ${isSelected ? "" : "hover:border-b-transparent"}`}
      >
        <button className={`btn btn-outline ${buttonClass}`}>
          Route {index + 1} {isSelected && <FontAwesomeIcon icon={faCheck} />}
        </button>
        <div className="flex flex-col items-start gap-1">
          {route?.summary?.map((group, index) => (
            <div key={index}>
              <div className="p-1 font-bold border rounded-md badge-outline bg-base-100 leading-5">
                {group?.group}
              </div>
              {index + 1 !== route?.summary?.length &&
                group.direction === route.summary[index + 1].direction && (
                  <RouteStraightVert />
                )}
              {index + 1 !== route?.summary?.length &&
                group.direction !== route.summary[index + 1].direction && (
                  <RouteReverseVert />
                )}
            </div>
          ))}
          <div className="text-base-content/50">
            {route?.distance?.toFixed(2)} mi
          </div>
        </div>
      </div>
    </div>
  );
}
