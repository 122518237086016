export default function StopTypeFlag() {
  return (
    <svg
      width="16"
      height="48"
      viewBox="0 0 16 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="8" y1="22" x2="8" stroke="#E13C55" strokeWidth="2" />
      <line x1="8" y1="48" x2="8" y2="26" stroke="#E13C55" strokeWidth="2" />
      <circle cx="8" cy="24" r="7" fill="white" stroke="#E13C55" strokeWidth="2" />
      <line x1="8" y1="26" x2="8" y2="19" stroke="#E13C55" strokeWidth="2" />
      <line x1="8" y1="29" x2="8" y2="27" stroke="#E13C55" strokeWidth="2" />
    </svg>
  );
}
